import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { DropdownTypes } from '../../../types/commonTypes';
import { Employee, EmployeeKTPOCR, EmployeeKkOCR, FamilySub } from '../../../types/Employee';
import { DropdownFamilyStatusJob } from '../../../types/Employee';

import { RootState } from '../../store';
import {
  getEmployeeDetail,
  getEmployeeDetailV2,
  getDropdown,
  saveDraft,
  saveCV,
  sendOCRKtp,
  sendOCRKk,
  getDropdownFamilyJob,
  getDropdownFamilyStatus,
  checkNIK,
  checkKK,
  saveDraftV2,
  saveCVV2,
  getDetailKkFromGoOcr
} from './employeeThunk';

export const employeeDetailSlice = createSlice({
  name: 'employeeDetail',
  initialState: {
    data: {} as Employee,
    ktpOCR: {} as EmployeeKTPOCR,
    kkOCR: {} as EmployeeKkOCR,
    loading: false,
    error: false,
  },
  reducers: {
    setDataUser: (state, action: PayloadAction<Employee>) => {
      state.data = action.payload;
    },
    resetOCR: (state) => {
      state.ktpOCR = {} as EmployeeKTPOCR
      state.kkOCR = {} as EmployeeKkOCR
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployeeDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getEmployeeDetail.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getEmployeeDetailV2.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployeeDetailV2.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getEmployeeDetailV2.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(saveDraft.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveDraft.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(saveDraft.rejected, (state) => {
        state.loading = false;
      })
      .addCase(saveDraftV2.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveDraftV2.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(saveDraftV2.rejected, (state) => {
        state.loading = false;
      })
      .addCase(saveCV.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveCV.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(saveCVV2.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveCVV2.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendOCRKtp.rejected, (state) => {
        state.loading = false;
      })
      .addCase(sendOCRKtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendOCRKtp.fulfilled, (state, action) => {
        state.loading = false;
        state.ktpOCR = action.payload
      })
      .addCase(checkNIK.rejected, (state) => {
        state.loading = false;
      })
      .addCase(checkNIK.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkNIK.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(checkKK.rejected, (state) => {
        state.loading = false;
      })
      .addCase(checkKK.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkKK.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendOCRKk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(sendOCRKk.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendOCRKk.fulfilled, (state, action) => {
        state.loading = false;
        state.kkOCR = action.payload
      })
      .addCase(getDetailKkFromGoOcr.fulfilled, (state, action) => {
        state.loading = false;
        const newKkGoOcr: EmployeeKkOCR = {
          attempt: 0,
          label: "kk-label",
          url_photo: action?.payload?.kartu_keluarga?.excel_url || "",
          kk: {
            nomor_kk: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: action?.payload?.kartu_keluarga?.family_head_details[0]?.id_number,
            },
            nama: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: action?.payload?.kartu_keluarga?.family_head_details[0]?.family_head_name,
            },
            alamat: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: action?.payload?.kartu_keluarga?.family_head_details[0]?.address,
            },
            kabupaten_kota: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: action?.payload?.kartu_keluarga?.family_head_details[0]?.city,
            },
            kecamatan: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: action?.payload?.kartu_keluarga?.family_head_details[0]?.ub_district,
            },
            kode_pos: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: action?.payload?.kartu_keluarga?.family_head_details[0]?.postal_code,
            },
            provinsi: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: action?.payload?.kartu_keluarga?.family_head_details[0]?.province,
            },
            kel_desa_kelurahan: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: action?.payload?.kartu_keluarga?.family_head_details[0]?.administrative_village,
            },
            rt_rw: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: "",
            },
          },
          kk_sub: []
        }

        const familyDetailLength: number = action?.payload?.kartu_keluarga?.family_details?.length || 0
        for (let idx = 0; idx < familyDetailLength; idx++) {
          const currentFamily = action?.payload?.kartu_keluarga?.family_details[idx]
          const familyMember: FamilySub = {
            nama: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily.full_name,
            },
            nik: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily.nik,
            },
            tempat_lahir: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily.place_of_birth,
            },
            tgl_lahir: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily.date_of_birth,
            },
            jenis_kelamin: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily["gender(male/female)"] || "",
            },
            kewarganegaraan: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily.citizenship,
            },
            ayah: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily.name_of_father,
            },
            ibu: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily.name_of_mother,
            },
            gender: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily["gender(male/female)"] || "",
            },
            pendidikan: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily.education,
            },
            pekerjaan: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily.occupation,
            },
            status_hubungan: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily.relationship_position_in_the_family,
            },
            status_perkawinan: {
              confidence: 0,
              status: 0,
              status_name: "0",
              value: currentFamily.marital_status,
            },
          }

          newKkGoOcr.kk_sub?.push(familyMember)
        }

        state.kkOCR = newKkGoOcr
      });
  },
});

export const dropdownSlice = createSlice({
  name: 'dropdownMaster',
  initialState: {
    data: {},
    dataFamilyJob: [] as DropdownFamilyStatusJob[], // Ensure it is an array
    dataFamilyStatus: [] as DropdownFamilyStatusJob[], // Ensure it is an array
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDropdown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDropdown.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getDropdownFamilyJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDropdownFamilyJob.fulfilled, (state, action) => {
        state.loading = false;
        state.dataFamilyJob = action.payload; // Update the property to match the response type
      })
      .addCase(getDropdownFamilyStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDropdownFamilyStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.dataFamilyStatus = action.payload; // Update the property to match the response type
      });
  },
});

export const { setDataUser, resetOCR } = employeeDetailSlice.actions;

export const selectData = (state: RootState) => state.employee;

export const reducer = combineReducers({
  employee: employeeDetailSlice.reducer,
  dropdown: dropdownSlice.reducer,
});

export default reducer;
